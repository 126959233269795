import swiper from 'swiper/bundle'
import 'swiper/swiper-bundle.css'

// Featured Slider
var feature_slider = new swiper('.feature-slider-container', {
    direction: 'horizontal',
    autoHeight: true,
    loop: true,
    parallax: true,
    watchOverflow: true,
    fadeEffect: {
      crossFade: true
    },  
    effect: 'fade',
    // autoplay: {
    //   delay: 8000,
    // },
    pagination: {
      el: '.feature-slider-controls',
      clickable: true,
      renderBullet: function (index, className) {
        return '<span class="' + className + '">0' + (index + 1) + '</span>';
      },
    },
});
// Content Slider
  var large_content_slider = new swiper('.swiper-container', {
    direction: 'horizontal',
    autoHeight: false,
    loop: false,
    watchOverflow: true,
    fadeEffect: {
      crossFade: true
    },  
    effect: 'fade',
    navigation: {
      nextEl: '.swiper-next',
      prevEl: '.swiper-prev',
    },
    pagination: {
      el: '.content-slider-controls',
      clickable: true,
      renderBullet: function (index, className) {
        return '<span class="' + className + '">0' + (index + 1) + '</span>';
      },
    },
});
// Buzz Slider
var buzz_content_slider = new swiper('.buzz-slider .swiper-container', {
  direction: 'horizontal',
  fadeEffect: {
    crossFade: true
  },  
  effect: 'fade',
  autoplay: {
    delay: 8000,
  },
  pagination: {
    el: '.content-slider-controls',
    clickable: true,
    renderBullet: function (index, className) {
      return '<span class="' + className + '">0' + (index + 1) + '</span>';
    },
  },
});
// Wines Swiper
// var galleryThumbs = new swiper('.wine-thumbs', {
//     spaceBetween: 10,
//     slidesPerView: 3,
//     loop: false,
//     freeMode: true,
//     loopedSlides: 4, //looped slides should be the same
//     watchSlidesVisibility: true,
//     watchSlidesProgress: true,
// });
// var galleryTop = new swiper('.wine-top', {
//     cssMode: true,
//     spaceBetween: 10,
//     loop: true,
//     loopedSlides: 4, //looped slides should be the same
//     navigation: {
//       nextEl: '.swiper-button-next',
//       prevEl: '.swiper-button-prev',
//     },
//     pagination: {
//       el: '.wine-slider-controls',
//       clickable: true,
//       renderBullet: function (index, className) {
//         return '<span class="' + className + '">0' + (index + 1) + '</span>';
//       },
//     },
//     thumbs: {
//       swiper: galleryThumbs,
//     },
// });