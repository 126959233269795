// import main SASS file
import '../../styles/scss/style.scss'

// Import Foundation
import './init-foundation'

// Load JS modules to import at runtime
import './modules/globals'
import './modules/carousel'
//import './modules/lazyload'
import './modules/fancybox'